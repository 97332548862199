
import { onMounted, ref, watch } from "vue";
import ApiService from "../../core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "EquipmentTypesSelect",
  props: {
    equipment_type_id: {
      type: Number,
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:equipment_type_id"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const inputEquipmentType = ref({
      loading: false,
      options: [],
      list: [],
    });

    const isEquipmentTypeRequired = (value) => {
      if (props.required && !value) {
        return t("requipmenttype");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "equipmentType",
      isEquipmentTypeRequired
    );

    const getEquipmentType = () => {
      store.commit("setLoading", false);
      ApiService.get("/api/equipment-types/lists").then(({ data }) => {
        inputEquipmentType.value.list = data.equipment_types;
        inputEquipmentType.value.options = data.equipment_types;
      });
    };
    const selectEquipmentType = (query) => {
      if (query !== "") {
        inputEquipmentType.value.loading = true;
        setTimeout(() => {
          inputEquipmentType.value.loading = false;
          inputEquipmentType.value.options =
            inputEquipmentType.value.list.filter(
              (item: Record<string, any>) => {
                return (
                  item.name.toLowerCase().indexOf(query.toLowerCase()) > -1
                );
              }
            );
          if (!inputEquipmentType.value.options.length) {
            store.commit("setLoading", false);
            ApiService.query(`/api/equipment-types/lists`, {
              params: { per_page: 25, name: query },
            }).then(({ data }) => {
              inputEquipmentType.value.list = data.equipment_types;
              inputEquipmentType.value.options = data.equipment_types;
            });
          }
        }, 200);
      } else {
        inputEquipmentType.value.options = [];
      }
    };

    watch(
      () => props.equipment_type_id,
      (first) => {
        element_id.value = first;
      }
    );

    onMounted(() => {
      setTimeout(function () {
        getEquipmentType();
      }, 3000);
    });

    return {
      element_id,
      inputEquipmentType,
      errorMessage,
      getEquipmentType,
      selectEquipmentType,
    };
  },
};
